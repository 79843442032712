const defaultConfig = {
  serverUrl: process.env.NEXT_PUBLIC_SERVER_URL,
  sentryDsn: (process.env.NEXT_PUBLIC_SENTRY_DSN || ""),
  release: (process.env.NEXT_PUBLIC_RELEASE || "dev"),
  env: (process.env.NEXT_PUBLIC_APP_ENV || "dev"),
  brandName: 'Troc Actif',
  currencyName: 'Actif €',
  calendlyHref: 'https://outlook.office365.com/owa/calendar/InsdeTrocActif@franceactiveidf.fr/bookings/',
  contactEmail: 'contact@korp.co',
  publicUrl: 'https://todo-troc-actif.fr',
}

const cleanConfig = <T extends Record<string, any>>(obj: Record<string, any>) =>
  Object.entries(obj).reduce<Record<string, any>>((prev, [key, value]) => {
    if (typeof value === "string") {
      if (value.startsWith("__PLACEHOLDER__")) {
        console.warn(`Variable ${key} not defined`);
        prev[key] = undefined;
      } else {
        prev[key] = value.trim();
      }
    } else if (value instanceof RegExp) {
      prev[key] = value;
    } else if (typeof value === "object") {
      prev[key] = cleanConfig(value);
    } else {
      prev[key] = value;
    }
    return prev;
  }, {}) as T;

// cleanConfig is used to clear whitespaces from padded variables (cf doc ../../../marketplace.Dockerfile)
// and remove __PLACEHOLDER__ not replaced by env variables
const config = cleanConfig<typeof defaultConfig>(defaultConfig);

export default config;
